const hybriddes = [
    {
        description:
            'Building Out Hybrid Applications Relevant To The Business Case Of Our Customers',
            description1: 'Enhancements & Extensibility Of Feature Sets across Multi-Domain & Cross-Functional Experiences.'
    }
];

const hybridMobile = [
    {
        title: 'Hybrid Mobile Applications',
        paragraph: 'Marketplace for e-Waste Management | Health App for Social Awareness | Unlisted Equity Market-Making Platform | Health Information Monitoring | EV Charging Platform.',
        subheading: 'Services Delivered'
    }
];

const data = [
    {
        description:
            'Consulting on Pipeline Architecture, Technology Road Map & Engineering Management',
            description1:'Analytics @ Scale, Process Automation & Data Visualization.',
    },
  
];
const shikshaDes = [
    {
        description:
            'Gather Content, User, Transactional & Telemetry Data and Derive Meaningful Insights on User Behavior, Content Consumption and Portal Performance.'
    }
];
const impact = [
    {
        description:
            'Reliable Analytics Focused on Improvement of the Societal Platform Features to enhance User Behavior and Retention.'}
];

const Recycle = [
    {
        data: 'Customised Design & Business Interactions '
    },
    {
        data: 'Multi-Lingual Applications'
    },
    {
        data: 'Real-Time Data Monitoring & Visualisation'
    },
    {
        data: 'Third Party Platform & App Integration'
    },
    {
        data: 'Offline First Approach'
    },
    {
        data: 'B2B & B2C Mobile Application Workflows'
    }
];

const EngineeringData = [
    {
        data: 'Data Validation, Automation and Reporting'
    },
    {
        data: 'Ingestion of Platform/Partner Data & User Analysis'
    },
    {
        data: 'Visualization of the Contextual Data'
    },
    {
        data: 'Creation of Derived Variables for Engagement'
    },
    {
        data: 'Data Model Design & Data Migration'
    },
    {
        data: 'Real Time Dashboards'
    }
];


module.exports = {
    hybriddes,
    data,
    hybridMobile,
    shikshaDes,
    impact,
    Recycle,
    EngineeringData
};
