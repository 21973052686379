import React, { useState } from 'react';
import { Typography, Grid, Paper, Container, Button } from '@mui/material';

import {
    capacity,
    impact,
    lyv,
    services,
    zenequity,
    servicesFeatured
} from '../../components/datas/Capacity';
import onlineImg from '../../images/online.svg';
import groupImg from '../../images/group.svg';
import mask from '../../images/maskCareers.svg';
import { makeStyles } from '@mui/styles';
import Popup from '../../components/Popup';
import Form from '../Form';

const useStyles = makeStyles({
    span: {
        color: '#BECE20',
        fontWeight: '600',
        letterSpacing: '2.5px',
        '@media (max-width: 320px)': {
            fontSize: '1.875rem',
            letterSpacing: '0.5px'
        }
    },
    LyvImage: {
        position: 'relative',
        '@media (min-width: 320px) and (max-width:375px)': {
            height: '150px !important'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            height: '150px !important'
        }
    },
    Techwatermarks: {
        float: 'right',
        position: 'absolute',
        width: '30%',
        height: '60%',
        right: '0px',
        zIndex: '9.9999',
        '@media (min-width: 320px) and (max-width:375px)': {
            width: '60%',
            height: '15%'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            width: '60%',
            height: '18%'
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            width: '60%',
            height: '20%'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            width: '40%',
            height: '34%',
            marginTop: '-2.4rem'
        },
        '@media (min-width: 1024px) and (max-width:1440px)': {
            width: '30%',
            height: '60%'
        }
    }
});
const Capacity = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    return (
        <>
            <Container>
                <>
                    <Grid item>
                        <Grid md={12} sm={12} xs={12} sx={{ mt: '0.5rem' }}>
                            <Typography variant="h1">
                                Capacity <span className={classes.span}>Enhancement</span>
                            </Typography>
                        </Grid>

                        <Grid md={2}></Grid>

                        <Grid sm={12} xs={8} md={12} lg={12} classes="desc_grid">
                            {capacity.map((item) => (
                              
                                    <Typography variant="bodyText2">{item.description}</Typography>

                                  
                            ))}
                            <Grid sm={12} xs={8} md={12} lg={12}>
                            {capacity.map((item) => (
                                <Typography variant="bodyText2">{item.description1}</Typography>
                            ))}
                        </Grid>
                        <Typography sx={{ textAlign: 'center', marginTop: "10px" }}>
                            <Button
                                variant="contained1"
                                onClick={() => setOpenPopup(true)}
                                sx={{
                                    padding: '5px !important'
                                }}
                            >
                                LET's TALK
                            </Button>
                        </Typography>
                        </Grid>
                        
                    </Grid>
                </>
            </Container>
            <Grid md={12}>
                <Paper variant="product_education_banner">
                    <Container>
                        <img
                            src={mask}
                            placeholder="none"
                            className={classes.Techwatermarks}
                            alt="Img"
                        />

                        <Grid
                            container
                            spacing={{ xs: 2, md: 5 }}
                            columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                        >
                            <Grid item xs={4} sm={5} md={9} lg={9} classes="grid_1">
                                <Typography variant="h7">
                                    Personalised Patient Care Platform
                                </Typography>
                                <Grid item xs={4} sm={12} md={12} lg={12} classes="Education_grid">
                                    {lyv.map((item) => (
                                        <Typography variant="bodyText5">
                                            {item.paragraph}
                                        </Typography>
                                    ))}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <img
                                    src={onlineImg}
                                    alt="mask"
                                    height="150"
                                    // className={classes.LyvImage}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} classes="service_grid" sx={{mt:'0.5px'}}>
                            <Typography variant="subTitle6">Services Enabled</Typography>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                pt: '1rem',
                                pb: '1rem',
                                display: 'flex'
                            }}
                        >
                            {services.map((item) => (
                                <Grid item xs={12} sm={6} md={4} textAlign="center">
                                    <Button variant="chip" size="large" disableRipple>
                                        {item.data}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 5 }}
                            columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
                            classes='data_grid'
                        >
                            <Grid item xs={6} sm={12} md={12} lg={12}>
                                <Typography variant="subTitle6">Impact</Typography>
                                <Grid item xs={6} sm={12} md={12} lg={12} sx={{ mt: '1rem' }}>
                                    {impact.map((item) => (
                                        <Typography variant="bodyText6">
                                            {item.description}
                                        </Typography>
                                    ))}
                                </Grid>
                                <Grid item xs={6} sm={12} md={12} lg={12}>
                                    {impact.map((item) => (
                                        <Typography variant="bodyText6">
                                            {item.description1}
                                        </Typography>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Grid>
            <Typography sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' }, marginTop: '20px', }} />
            <Grid md={12}>
                <Grid classes="product_education_banner_sub">
                    <Container>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 5 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                            <Grid item xs={4} sm={5} md={9} lg={9} classes="grid_2">
                                <Typography variant="h7">
                                Employee Stock Option Management Platform
                                </Typography>
                                <Grid
                                    item
                                    xs={4}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    classes="Education_grid"
                                >
                                    {zenequity.map((item) => (
                                        <Typography variant="bodyText5">
                                            {item.description}
                                        </Typography>
                                    ))}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3} md={3} lg={3} classes="recycle_grid">
                                <img src={groupImg} alt="mask" height="150" />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} classes="sub_service" sx={{mt:'0.5px'}}>
                            <Typography variant="subTitle6">Services Enabled</Typography>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                pt: '1rem',
                                pb: '1rem',
                                display: 'flex'
                            }}
                        >
                            {servicesFeatured.map((item) => (
                                <Grid item xs={12} sm={6} md={4} textAlign="center">
                                    <Button variant="chip" size="large" disableRipple>
                                        {item.data}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 5 }}
                            columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
                        >
                            <Grid item xs={6} sm={6} md={12} lg={12}>
                                <Typography variant="subTitle6">Impact</Typography>
                                <Grid
                                    item
                                    xs={6}
                                    sm={8}
                                    md={7.5}
                                    lg={7.5}
                                    xl={12}
                                    sx={{ mt: '1rem' }}
                                >
                                    {impact.map((item) => (
                                        <Typography variant="bodyText6">
                                            {item.descriptiondata}
                                        </Typography>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
            <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <Form setOpenPopup={setOpenPopup} />
                {/* <Form /> */}
            </Popup>
        </>
    );
};

export default Capacity;
