import React, { useState } from 'react';
import { Typography, Grid, Paper, Container, Box, Button } from '@mui/material';
import { hybriddes, hybridMobile, Recycle, impact } from '../../components/datas/Hybrid';
import hybrid from '../../images/Hybrid.svg';
import { makeStyles } from '@mui/styles';
import mask from '../../images/maskCareers.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Popup from '../../components/Popup';
import Form from '../Form';

const useStyles = makeStyles({
    span: {
        color: '#BECE20',
        fontWeight: '600',
        letterSpacing: '2.5px',
        '@media (max-width: 320px)': {
            fontSize: '1.875rem',
            letterSpacing: '0.5px'
        }
    },
    Hybridwatermarks: {
        float: 'right',
        position: 'absolute',
        width: '30%',
        height: '35%',
        right: '0px',
        marginTop: '-1.5rem',
        '@media (min-width: 320px) and (max-width:375px)': {
            width: '60%',
            height: '25% !important'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            width: '60%',
            height: '25%'
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            width: '33%',
            height: '15%'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            width: '45% !important',
            height: '38% !important'
        }
    },
    HybridImage: {
        position: 'relative',
        marginLeft: '2rem',

        '@media (max-width: 1024px)': {
            marginLeft: '0 !important'
        }
    },
    watermarks: {
        float: 'right',
        position: 'absolute',
        width: '30%',
        height: '40%',
        right: '0px',
        marginTop: '-2.5rem',
        '@media (min-width: 320px) and (max-width:375px)': {
            width: '60% !important',
            height: '20% !important'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            width: '60% !important',
            height: '23% !important'
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            width: '60% !important',
            height: '23% !important'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            width: '38%!important',
            height: '46% !important',
            marginTop: '-4rem'
        },
        '@media (min-width: 1024px) and (max-width:1440px)': {
            width: '25% !important',
            height: '50% !important'
        }
    }
});

const Hybrid = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    let AppData =
        'Enabled health information monitoring for Doctors to view and support patients in real time ';
    let Market = 'Developed the Marketplace App for E-waste management & buyers';
    let Rural = 'Health App for the Social awareness and menstrual hygiene care of Rural women';
    let offlineData = 'Offline First Approach';
    let RealData = 'Realtime Data Monitoring & Built-In Data Management';
    let MultiData = 'Multilingual Applications';

    return (
        <div>
            <Container>
                <>
                    <Grid item>
                        <Grid md={12} sm={12} xs={12} sx={{ mt: '0.5rem' }}>
                            <Typography variant="h1">
                                Mobile App{' '}
                                <span
                                    style={{
                                        color: '#BECE20',
                                        fontWeight: '600',
                                        letterSpacing: '2.5px'
                                    }}
                                >
                                    Development{' '}
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid md={2} />
                    <Grid md={12} sm={12} xs={12} classes="desc_grid">
                        {hybriddes &&
                            hybriddes.map((item) => (
                                <>
                                    <Typography variant="bodyText2">{item.description}</Typography>
                                    <br />
                                    <Typography sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' }, marginTop: '6px', }} />
                                    <Typography variant="bodyText2">{item.description1}</Typography>
                                </>
                            ))}
                    </Grid>
                    <Typography sx={{ textAlign: 'center', marginTop: 0, marginBottom:'20px' }}>
                            <Button
                                variant="contained1"
                                onClick={() => setOpenPopup(true)}
                                sx={{
                                    padding: '5px !important'
                                }}
                            >
                                LET's TALK
                            </Button>
                        </Typography>

                    <Box></Box>
                </>
            </Container>
            <Grid md={12}>
                
                    <Container>
                        

                            <Grid
                                container
                                spacing={{ xs: 2, md: 1 }}
                                columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                            >
                                <Grid item xs={4} sm={5} md={9} lg={9} classes="grid_1">
                                    <Typography variant="h7">Hybrid Mobile Applications</Typography>

                                    <Grid
                                        item
                                        xs={4}
                                        sm={9}
                                        md={12}
                                        lg={12}
                                        classes="Education_grid"
                                    >
                                        {hybridMobile.map((item) => (
                                            <Typography variant="bodyText5">
                                                {item.paragraph}
                                            </Typography>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={3} md={3} lg={3}>
                                    <img
                                        src={hybrid}
                                        alt="mask"
                                        height="120"
                                        className={classes.HybridImage}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <Typography variant="subTitle6">Services Enabled</Typography>
                                </Grid>
                                <Grid
                                    container
                                    sx={{
                                        pt: '1rem',
                                        pb: '1rem',
                                        display: 'flex'
                                    }}
                                >
                                    {Recycle.map((item) => (
                                        <Grid item xs={12} sm={6} md={4} textAlign="center">
                                            <Button variant="chip" size="large" disableRipple>
                                                {item.data}
                                            </Button>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={{ xs: 2, md: 5 }}
                                columns={{ xs: 4, sm: 12, md: 12 }}
                                classes="data_grid"
                            >
                                <Grid item xs={6} sm={12} md={12} lg={12}>
                                    <Typography variant="subTitle6">Impact</Typography>
                                    <Grid item xs={4} sm={12} md={12} lg={12} sx={{ mt: '1rem' }}>
                                        <Typography variant="bodyText6">
                                            Combining User Experience & Agile Development, Enabling
                                            Easier Continuity of Maintenance, Reducing Development
                                            Time and Costs.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        
                    </Container>
                
            </Grid>
            <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <Form setOpenPopup={setOpenPopup} />
                {/* <Form /> */}
            </Popup>
        </div>
    );
};

export default Hybrid;
