const capacity = [
    {
        title: 'Capacity Enhancement'
    },
    {
        description:
            'Collaboratively Work with the Customer Team in the Technology Vision, Platform Architecture,  Extending the Modules, Feature Sets & Connected Application Environment.'
            
    },
    // {description1:'Team comprising of 15+ Engineers Architects, Tech Leads, Full Stack Engineers scale for customer team in cost effective manner : integral part of their team now.'}
];

const lyv = [
    {
        title: 'LYV',
        paragraph:
            'Feature Enhancement for Clinical Decision Support System for Effective Dosing & Monitoring.'
    }
];
const services = [
    {
        data: 'Platform Architecture'
    },
    {
        data: 'Feature Enhancements & Extensibility'
    },
    {
        data: 'Workflow Enablement'
    },
    {
        data: 'Multi Vendor-Partner Environment'
    },
    {
        data: 'Shortening Road-Map Milestones'
    },
    {
        data: 'Adoption to New Technologies'
    }
];
const impact = [
    {
        title: 'Impact'
    },
    

    {
        // description:
        //     'Deployed across USA’s Luminary Hospital Chains.',
            description1:'Enabled Personalized Treatment Trajectories for Radically Improved Patient Outcomes.'
    },
    {
        descriptiondata:
            'Improved Speed of Deployments on New Feature Sets for the End User Base of ~50k Integral Part of Core Team : Increased Delivery Competence of Customer Team.'
    }
];

const zenequity = [
    {
        title: 'ZENEQUITY'
    },
    {
        description:
            'Collaboratively Work to Enhance & Streamline Platform to Manage Employee Stock Options.'
    }
];

const servicesFeatured = [
    {
        data: 'Full Stack Software Development '
    },
    {
        data: 'Feature Set Enhancements'
    },
    {
        data: 'Adopting Customer Process Methodologies'
    },
    {
        data: 'Flexible Team Compositions & Competence'
    },
    {
        data: 'De-Risking & Business Continuity'
    },
    {
        data: 'Build Operate Transfer Model'
    }
];

module.exports = {
    capacity,
    impact,
    lyv,
    services,
    zenequity,
    servicesFeatured
};
