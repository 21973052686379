import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { useState } from 'react';
import Header from '../../components/Header/index';
import { StaticImage } from 'gatsby-plugin-image';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Footer from '../../components/Footer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ProductsData from './Product';
import Technology from './Technology';
import Hybrid from './Hybrid';
import ProductConsulting from './ProductConsulting';
import DataEngineering from './DataEngineering';
import Capacity from './Capacity';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import BiotechIcon from '@mui/icons-material/Biotech';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import DataArrayIcon from '@mui/icons-material/DataArray';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from '@reach/router';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material';

const CustomerEngagement = () => {
    const [activeButton, setActiveButton] = useState('');
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(location.state?.id ? location.state?.id : 0);
    const tabTitle = [
        'Product & Platform Engineering',
        'Technology Consulting',
        'Mobile App Development',
        'Product Consulting',
        'Data Engineering',
        'Capacity Enhancement'
    ];

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleClick = (button) => {
        setActiveButton(button);
    };

    const customTheme = createTheme({
        breakpoints: {
          values: {
            
            md: 1220,
          },
        },
      });

    const useStyles = makeStyles((theme) => ({
        tabsFlexContainer: {
          justifyContent: 'center',
          [customTheme.breakpoints.down('md')]: {
            justifyContent: 'initial',
          },
        },
      }));
      const classes = useStyles();
      
    return (
        <div>
            <Header />

            <Paper
                variant="Customer_Banner"
                id="back-to-top-anchor"
                sx={{
                    position: 'relative'
                }}
            >
                <Grid md={12} xs={12} sm={12}>
                    <Box sx={{ justifyContent: 'center'}}>
                        {/* <Container> */}
                            <Tabs
                                value={tabIndex}
                                onChange={handleTabChange}
                                aria-label="ant example"
                                variant="scrollable"
                                allowScrollButtonsMobile
                                justifyContent="center"
                                align="center"
                                classes={{ flexContainer: classes.tabsFlexContainer }}
                                sx={{
                                    position: 'fixed',
                                    textTransform: 'none',
                                    justifyContent: 'center',
                                    width: '100%',
                                    backgroundColor: '#E0DFDE',
                                    top: 82,
                                    zIndex: 1,
                                    '@media (min-width: 320px) and (max-width:599px)': {
                                        top: 55,
                                    },
                                    '@media (min-width: 600px) and (max-width:899px)': {
                                        top: 64,
                                    },
                                    '@media (min-width: 900px) and (max-width:950px)': {
                                        top: 80,
                                    },
                                    '& .MuiTabs-indicator': {
                                        display: 'none'
                                    },

                                    '& .MuiTab-root ': {
                                        color: '#343333 !important',
                                    },
                                    '& .Mui-selected': {
                                        color: '#A6B50C !important',
                                        fontWeight: '700 !important'
                                    },
                                    // '& .MuiTabs-flexContainer': {
                                    //     padding: 0,
                                    //     justifyContent: 'center'
                                    // }
                                }}
                            >
                                {tabTitle.map((item) => {
                                    return (
                                        <Tab
                                            label={item}
                                            justifyContent="initial"
                                            sx={{
                                                '&.MuiTab-root ': {
                                                    color: '#343333',
                                                    textTransform: 'initial  !important',
                                                    fontFamily: 'Poppins',
                                                    opacity: '1 !important',
                                                    fontSize: '12.5px !important',
                                                    fontWeight: '500 ',
                                                    letterSpacing: '0.2px',
                                                    marginLeft: '13px',
                                                    marginRight: '6.5px'
                                                },
                                                'MuiTabs-flexContainer': {
                                                    // justifyContent: 'initial'
                                                }
                                            }}
                                        />
                                    );
                                })}
                            </Tabs>
                        {/* </Container> */}
                    </Box>
                    <Grid sx={{ display: { xs: 'none', md: 'flex', sm: 'flex' }, 
                    // mt:'200px' 
                    }}>
                        <Box>
                            <StaticImage
                                src="../../images/Customer.png"
                                alt="mask"
                                placeholder="none"
                            />
                        </Box>
                    </Grid>
                    <Grid sx={{ display: { xs: 'flex', md: 'none', sm: 'none' } }}>
                        <Box>
                            <StaticImage
                                src="../../images/Customer.png"
                                alt="mask"
                                placeholder="none"
                                height={500}
                                style={{ marginTop: '45px' }}
                            />
                        </Box>
                    </Grid>
                    <Grid md={12} xs={12} sm={12}>
                        <Grid md={6} xs={12} sm={12} classes="banner_grid">
                            <Typography variant="subTitle8">Customer</Typography>
                            <br />
                            <Typography variant="subTitle1">Engagements</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            {tabIndex === 0 && (
                <Box>
                    <ProductsData />
                </Box>
            )}
            {tabIndex === 1 && (
                <Box>
                    <Typography>
                        <Technology />
                    </Typography>
                </Box>
            )}
            {tabIndex === 2 && (
                <Box>
                    <Typography>
                        <Hybrid />
                    </Typography>
                </Box>
            )}
            {tabIndex === 3 && (
                <Box>
                    <Typography>
                        {' '}
                        <ProductConsulting />
                    </Typography>
                </Box>
            )}
            {tabIndex === 4 && (
                <Box>
                    <Typography>
                        {' '}
                        <DataEngineering />
                    </Typography>
                </Box>
            )}
            {tabIndex === 5 && (
                <Box>
                    <Typography>
                        {' '}
                        <Capacity />
                    </Typography>
                </Box>
            )}

            <br />
            <Box
                sx={{
                    transform: 'translateZ(0px)',
                    flexGrow: 1,
                    marginBottom: '1rem'
                }}
            >
                <SpeedDial
                    ariaLabel="SpeedDial"
                    sx={{ position: 'absolute', bottom: '-12px', right: '16px', Color: '#bece20' }}
                    FabProps={{
                        sx: {
                            bgcolor: '#BECE20',
                            width: 35,
                            height: 30,
                            padding: 0,
                            margin: 0,
                            '&:hover': {
                                bgcolor: '#BECE20'
                            }
                        }
                    }}
                    icon={<MenuIcon />}
                >
                    {activeButton !== 'Product & Platform Engineering' && (
                        <SpeedDialAction
                            label="Product & Platform Engineering"
                            onClick={() => {
                                setTabIndex(0);
                                window.scrollTo(0, 0);
                                handleClick('Product & Platform Engineering');
                            }}
                            icon={<ProductionQuantityLimitsIcon />}
                            tooltipTitle="Product & Platform Engineering"
                            sx={{
                                width: 35,
                                height: 30,
                                padding: 0,
                                margin: 0.5,
                                disabled: 'true'
                            }}
                        />
                    )}
                    {activeButton !== 'Technology Consulting' && (
                        <SpeedDialAction
                            label="Technology Consulting"
                            onClick={() => {
                                setTabIndex(1);
                                window.scrollTo(0, 0);
                                handleClick('Technology Consulting');
                            }}
                            icon={<BiotechIcon />}
                            tooltipTitle="Technology Consulting"
                            sx={{ width: 35, height: 30, padding: 0, margin: 0.5 }}
                        />
                    )}
                    {activeButton !== 'Hybrid Mobile App Development' && (
                        <SpeedDialAction
                            label="Hybrid Mobile App Development"
                            onClick={() => {
                                setTabIndex(2);
                                window.scrollTo(0, 0);
                                handleClick('Hybrid Mobile App Development');
                            }}
                            icon={<AddShoppingCartIcon />}
                            tooltipTitle="Hybrid Mobile App Development"
                            sx={{ width: 35, height: 30, padding: 0, margin: 0.5 }}
                        />
                    )}
                    {activeButton !== 'Product Consulting' && (
                        <SpeedDialAction
                            label="Product Consulting"
                            onClick={() => {
                                setTabIndex(3);
                                window.scrollTo(0, 0);
                                handleClick('Product Consulting');
                            }}
                            icon={<CoPresentIcon />}
                            tooltipTitle="Product Consulting"
                            sx={{ width: 35, height: 30, padding: 0, margin: 0.5 }}
                        />
                    )}
                    {activeButton !== 'Data Engineering' && (
                        <SpeedDialAction
                            label="Data Engineering"
                            onClick={() => {
                                setTabIndex(4);
                                window.scrollTo(0, 0);
                                handleClick('Data Engineering');
                            }}
                            icon={<DataArrayIcon />}
                            tooltipTitle="Data Engineering"
                            sx={{ width: 35, height: 30, padding: 0, margin: 0.5 }}
                        />
                    )}
                    {activeButton !== 'Capacity Enhancement' && (
                        <SpeedDialAction
                            label="Capacity Enhancement"
                            onClick={() => {
                                setTabIndex(5);
                                window.scrollTo(0, 0);
                                handleClick('Capacity Enhancement');
                            }}
                            icon={<ReduceCapacityIcon />}
                            tooltipTitle="Capacity Enhancement"
                            sx={{ width: 35, height: 30, padding: 0, margin: 0.5 }}
                        />
                    )}
                </SpeedDial>
            </Box>
            <Footer />
        </div>
    );
};

export default CustomerEngagement;
