const technology = [
    {
        title: 'Technology Consulting'
    },
    {
        description:
            'Strategic Consulting on the Technology Vision, Platform Architecture & Road Map Enhancements.',
        description1:
            'Team of Multi Domain & Cross-Functional Expertise, Comprising Consultants & Full Stack Engineers, Architects, Business Analysts & others.'
    }
];

const Repositax = [
    {
        title: 'US TAX PREP ENGAGEMENT & AUTOMATION PLATFORM',
        paragraph:
            'R&D Initiatives for PoC on Templated Data Extraction of Tax forms with Production Grade Development.',
        paragraphs: 'Templated Data Extraction & Integrations to Enable Automation of the Platform.'
    }
];
const services = [
    {
        data: 'Platform Architecture'
    },
    {
        data: 'Feature Enhancements & Extensibility'
    },
    {
        data: 'Workflow Enablement'
    },
    {
        data: 'R&D, Proof of Concept (POC)'
    },
    {
        data: 'Templatized Data Extraction & Visualisation'
    },
    {
        data: 'Integration into Product Platform'
    }
];
const impact = [
    {
        title: 'Impact'
    },
    {
        description:
            'Making the Vision & Design Concepts of Reality. Productivity Enhancement Across the Platform , Digitization and Scalability of Processing Workflows.'
    },
    {
        descriptiondata:
            'Project Was Delivered for a Multi-Stakeholder and Multi Vendor Environment, Starting With Delivery for North America Teams and Then Deploy Globally.'
    }
];

const cisco = [
    {
        title: "NETWORKING CONGLOMERATE'S INDIA PARTNER FOR LEARNING MANAGEMENT"
    },
    {
        description:
            'Moving Legacy Sales & Learning CRM Systems into Salesforce Automation on Single Platform.'
    }
];

const servicesFeatured = [
    {
        data: 'Platform Migration Planning'
    },
    {
        data: 'Feature Enhancements & Extensibility'
    },
    {
        data: 'Multi Vendor Program Management'
    },
    {
        data: 'Automation of Workflow on Single Platform'
    },
    {
        data: 'Multi Stakeholder Requirement Prioritization '
    },
    {
        data: 'Third Party Platform integrations'
    }
];

module.exports = {
    technology,
    services,
    Repositax,
    impact,

    servicesFeatured,
    cisco
};
