import React, { useState } from 'react';
import { Typography, Grid, Paper, Container, Button } from '@mui/material';
import {
    technology,
    services,
    Repositax,
    servicesFeatured,
    cisco
} from '../../components/datas/Technology';

import RepoImg from '../../images/Repositax.svg';
import ciscoImage from '../../images/Cisco.svg';
import mask from '../../images/maskCareers.svg';
import { makeStyles } from '@mui/styles';
import Popup from '../../components/Popup';
import Form from '../Form';

const useStyles = makeStyles({
    Techwatermarks: {
        float: 'right',
        position: 'absolute',
        width: '25%',
        height: '50%',
        right: '0px',
        zIndex: '9.9999',
        marginTop:'-2rem',
        '@media (min-width: 320px) and (max-width:375px)': {
            width: '60%',
            height: '20%'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            width: '60%',
            height: '25%'
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            width: '60%',
            height: '28%'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            width: '20%',
            height: '45%',
            marginTop: '-4.4rem'
        },
        // '@media (min-width: 1024px) and (max-width:1440px)': {
        //     width: '25%',
        //     height: '45%',
        //     marginTop: '-2rem'
        // }
    },
    RepoImage: {
        position: 'relative',
        '@media (min-width: 320px) and (max-width:375px)': {
            marginLeft: 0,
            height: '180px'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            marginLeft: 0,
            height: '180px !important'
        },
        '@media (min-width: 420px) and (max-width:700px)': {
            marginLeft: 0,
            height: '180px !important'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            marginLeft: 0,
            height: '165px !important'
        }
    }
});

function Technology() {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    return (
        <div>
            <Container>
                <>
                    <Grid item>
                        <Grid md={12} sm={12} xs={12} sx={{ mt: '0.5rem' }}>
                            <Typography variant="h1">
                                Technology{' '}
                                <span
                                    style={{
                                        color: '#BECE20',
                                        fontWeight: '600',
                                        letterSpacing: '2.5px',
                                        '@media (max-width: 320px)': {
                                            fontSize: '1.875rem',
                                            letterSpacing: '0.5px'
                                        }
                                    }}
                                >
                                    Consulting
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid md={2}></Grid>

                    <Grid md={12} lg={12} classes="desc_grid">
                        {technology &&
                            technology.map((item) => (
                                <Typography variant="bodyText2">{item.description}</Typography>
                            ))}
                            <Typography sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' }, marginTop: '7px', }} />
                        <Grid md={12} lg={12}>
                            {technology &&
                                technology.map((item) => (
                                    <Typography variant="bodyText2">{item.description1}</Typography>
                                ))}
                        </Grid>
                        <Typography sx={{ textAlign: 'center', marginTop: 0 }}>
                            <Button
                                variant="contained1"
                                onClick={() => setOpenPopup(true)}
                                sx={{
                                    padding: '5px !important'
                                }}
                            >
                                LET's TALK
                            </Button>
                        </Typography>
                    </Grid>
                </>
            </Container>
            <Grid md={12}>
                <Paper variant="product_education_banner">
                    <Container>
                        <img
                            src={mask}
                            placeholder="none"
                            alt="none"
                            className={classes.Techwatermarks}
                        />

                        <Grid
                            container
                            spacing={{ xs: 2, md: 1 }}
                            columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                        >
                            <Grid item xs={4} sm={5} md={8.5} lg={9} classes="grid_1">
                                <Typography variant="h7">
                                    Tax Consulting Fintech Platform
                                    {/* US Tax Prep Engagement <br />& Automation Platform */}
                                </Typography>

                                <Grid item xs={4} sm={8} md={12} lg={12} classes="Education_grid">
                                    {Repositax.map((item) => (
                                        <Typography variant="bodyText5">
                                            {item.paragraph}
                                        </Typography>
                                    ))}
                                </Grid>
                            </Grid>

                            <Grid item xs={6} sm={3} md={3} lg={3}>
                                <img
                                    src={RepoImg}
                                    alt="mask"
                                    height="150"
                                    // className={classes.RepoImage}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} classes="service_grid" sx={{mt:'0.5px'}}>
                            <Typography variant="subTitle6">Services Enabled </Typography>
                        </Grid>

                        <Grid
                            container
                            sx={{
                                pt: '1rem',
                                pb: '1rem',
                                display: 'flex'
                            }}
                        >
                            {services.map((item) => (
                                <Grid item xs={12} sm={6} md={4} textAlign="center">
                                    <Button variant="chip" size="large" disableRipple>
                                        {item.data}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid
                            container
                            spacing={{ xs: 2, md: 5 }}
                            columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                            classes='data_grid'
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="subTitle6">Impact</Typography>
                                <Grid item xs={4} sm={8} md={12} lg={12} sx={{ mt: '1rem' }}>
                                    <Typography variant="bodyText6">
                                        Productivity Enhancement across the Platform, Scalability of
                                        Processing Workflows.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Grid>
            <Typography sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' }, marginTop: '20px', }} />
            <Grid md={12}>
                <Grid classes="product_education_banner_sub">
                    <Container>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 5 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                            <Grid item xs={4} sm={5} md={9} lg={9} classes="grid_2">
                                <Typography variant="h7">
                                Global Networking Company Partner                                 </Typography>
                                <Grid item xs={4} sm={8} md={12} lg={12} classes="Education_grid">
                                    {cisco.map((item) => (
                                        <Typography variant="bodyText5">
                                            {item.description}
                                        </Typography>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} classes="recycle_grid">
                                <img src={ciscoImage} alt="mask" height="150" />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} classes="sub_service" sx={{mt:'0.5px'}}>
                            <Typography variant="subTitle6">Services Enabled</Typography>

                            <Grid
                                container
                                sx={{
                                    pt: '1rem',
                                    pb: '1rem',
                                    display: 'flex'
                                }}
                            >
                                {servicesFeatured.map((item) => (
                                    <Grid item xs={12} sm={6} md={4} textAlign="center">
                                        <Button variant="chip" size="large" disableRipple>
                                            {item.data}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                            <Grid item xs={4} sm={12} md={12} lg={12}>
                                <Typography variant="subTitle6">Impact</Typography>
                                <Grid item xs={4} sm={12} md={12} lg={12} sx={{ mt: '1rem' }}>
                                    <Typography variant="bodyText6">
                                    Project Delivered for North America Team and Consultation on Global Deployment Plan.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
            <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <Form setOpenPopup={setOpenPopup} />
                {/* <Form /> */}
            </Popup>
        </div>
    );
}

export default Technology;
