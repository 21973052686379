import React, { useState } from 'react';
import { Typography, Grid, Paper, Container, Box, Button } from '@mui/material';

import { data, impact, shikshaDes, EngineeringData } from '../../components/datas/Hybrid';

import shikshalokam from '../../images/ShikshaLokam.svg';

import { makeStyles } from '@mui/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import mask from '../../images/maskCareers.svg';
import Popup from '../../components/Popup';
import Form from '../Form';

const useStyles = makeStyles({
    span: {
        color: '#BECE20',
        fontWeight: '600',
        letterSpacing: '2.5px',
        '@media (max-width: 320px)': {
            fontSize: '1.875rem',
            letterSpacing: '0.5px'
        }
    },
    datawatermarks: {
        float: 'right',
        position: 'absolute',
        width: '30%',
        height: '45%',
        right: '0px',
        '@media (min-width: 320px) and (max-width:375px)': {
            width: '50% !important',
            height: '18% !important',
            right: '0px',
            marginTop: 'none'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            width: '44% !important',
            height: '18% !important'
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            width: '40% !important',
            height: '43% '
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            width: '35% !important',
            height: '30% !important'
        }
    },
    dataImage: {
        position: 'relative',
        top: '2rem',
        '@media (min-width: 320px) and (max-width:375px)': {
            top: 0
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            top: 0
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            top: 0
        }
    },
    Hybridwatermarks: {
        float: 'right',
        position: 'absolute',
        width: '30%',
        height: '35%',
        right: '0px',
        marginTop: '-1.5rem',
        '@media (min-width: 320px) and (max-width:375px)': {
            width: '60%',
            height: '25% !important'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            width: '60%',
            height: '25%'
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            width: '33%',
            height: '15%'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            width: '45% !important',
            height: '38% !important'
        }
    },
    HybridImage: {
        position: 'relative',
        marginLeft: '2rem',

        '@media (max-width: 1024px)': {
            marginLeft: '0 !important'
        }
    },
    watermarks: {
        float: 'right',
        position: 'absolute',
        width: '30%',
        height: '40%',
        right: '0px',
        marginTop: '-2.5rem',
        '@media (min-width: 320px) and (max-width:375px)': {
            width: '60% !important',
            height: '20% !important'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            width: '60% !important',
            height: '23% !important'
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            width: '60% !important',
            height: '23% !important'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            width: '38%!important',
            height: '46% !important',
            marginTop: '-4rem'
        },
        '@media (min-width: 1024px) and (max-width:1440px)': {
            width: '25% !important',
            height: '50% !important'
        }
    }
});

const DataEngineering = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    const DataValidation =
        'Data Validation, Automation and Scheduling of Reports, Management Reports';
    const CreationData =
        'Creation of Derived Variables for Engagement & User Analysis, Ingestion of Platform/Partner Data';
    const Virtualisation =
        'Visualization of the Data With Reference to Enable Relevant and Timely Intervention Based on Contextual Data';
    return (
        <div>
            {' '}
            <Container>
                <>
                    <Grid item>
                        <Grid md={12} sm={12} xs={12} sx={{ mt: '0.5rem' }}>
                            <Typography variant="h1">
                                Data <span className={classes.span}> Engineering </span>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid md={2} />
                    <Grid md={12} sm={12} xs={12} classes="desc_grid">
                        {data &&
                            data.map((item) => (
                                <Typography variant="bodyText2">{item.description}</Typography>
                            ))}
                            <Typography sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' }, marginTop: '6px', }} />
                        <Grid md={12} sm={12} xs={12}>
                            {data &&
                                data.map((item) => (
                                    <Typography variant="bodyText2">{item.description1}</Typography>
                                ))}
                        </Grid>
                    </Grid>
                    <Typography sx={{ textAlign: 'center', marginTop: 0 , marginBottom: '25px'}}>
                            <Button
                                variant="contained1"
                                onClick={() => setOpenPopup(true)}
                                sx={{
                                    padding: '5px !important'
                                }}
                            >
                                LET's TALK
                            </Button>
                        </Typography>

                    <Box></Box>
                </>
            </Container>
            <Grid sm={12} md={12} lg={12}>
                
                    <Container>
                        
                            <Grid xs={12} md={12} lg={12} sm={12} sx={{ pt: '1rem', pb: '1rem' }}>
                                <Grid container spacing={{ xs: 2, md: 2 }}>
                                    <Grid item xs={12} sm={7} md={8} lg={9} classes="grid_2">
                                        <Typography variant="h7">
                                            Education Leadership Development Platform
                                        </Typography>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            classes="Education_grid"
                                        >
                                            {shikshaDes.map((item) => (
                                                <Typography variant="bodyText2">
                                                    {item.description}
                                                </Typography>
                                            ))}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={5} md={4} lg={3}>
                                        <img
                                            src={shikshalokam}
                                            alt="mask"
                                            height="150"
                                            classes="recycle_grid"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} sm={12} md={6} classes="data_eng" sx={{mt:'0.1rem'}}>
                                    <Typography variant="subTitle6">Services Enabled</Typography>
                                    <Grid
                            container
                            sx={{
                                pt: '1rem',
                                pb: '1rem',
                                display: 'flex'
                            }}
                        >
                            {EngineeringData.map((item) => (
                                <Grid item xs={12} sm={6} md={4} textAlign="center">
                                    <Button variant="chip" size="large" disableRipple>
                                        {item.data}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                                </Grid>

                                <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={12}
                                        sx={{ pt: '1rem', pb: '1rem' }}
                                    >
                                        <Typography variant="subTitle6">Impact</Typography>

                                        <Grid
                                            item
                                            xs={6}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            sx={{ pt: '1rem', pb: '1rem' }}
                                        >
                                            {impact.map((item) => (
                                                <Typography variant="bodyText6">
                                                    {item.description}
                                                </Typography>
                                            ))}
                                            <Grid item xs={6} sm={12} md={12} lg={12}>
                                                {impact.map((item) => (
                                                    <Typography variant="bodyText6">
                                                        {item.description1}
                                                    </Typography>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        
                    </Container>
            </Grid>
            <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <Form setOpenPopup={setOpenPopup} />
                {/* <Form /> */}
            </Popup>
        </div>
    );
};

export default DataEngineering;
